<template>
  <div id="custom_check_box">
    <Transition name="custom-check-box-bounce" mode="out-in">
      <button @click="switchValue" v-if="value">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.83333 13.5L14.7083 7.625L13.5417 6.45833L8.83333 11.1667L6.45833 8.79167L5.29167 9.95833L8.83333 13.5ZM4.16667 17.5C3.70833 17.5 3.31583 17.3369 2.98917 17.0108C2.66306 16.6842 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66306 3.31583 2.98917 2.98917C3.31583 2.66306 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.6842 2.66306 17.0108 2.98917C17.3369 3.31583 17.5 3.70833 17.5 4.16667V15.8333C17.5 16.2917 17.3369 16.6842 17.0108 17.0108C16.6842 17.3369 16.2917 17.5 15.8333 17.5H4.16667Z"
            fill="#4150B7"
          />
        </svg>
      </button>
      <button @click="switchValue" v-else>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.16667 17.5C3.70833 17.5 3.31583 17.3369 2.98917 17.0108C2.66306 16.6842 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66306 3.31583 2.98917 2.98917C3.31583 2.66306 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.6842 2.66306 17.0108 2.98917C17.3369 3.31583 17.5 3.70833 17.5 4.16667V15.8333C17.5 16.2917 17.3369 16.6842 17.0108 17.0108C16.6842 17.3369 16.2917 17.5 15.8333 17.5H4.16667ZM4.16667 15.8333H15.8333V4.16667H4.16667V15.8333Z"
            fill="#4150B7"
          />
        </svg>
      </button>
    </Transition>
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      value: false,
    };
  },
  methods: {
    switchValue() {
      this.value = !this.value;
      this.$emit("switchValue", this.value);
      this.$emit("update:checked", this.value);
    },
  },
  created() {
    this.value = this.checked;
  },
  watch: {
    checked(newValue) {
      this.value = newValue;
    },
  },
};
</script>

<style lang="less">
#custom_check_box {
  button {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .custom-check-box-bounce-enter-active {
    animation: custom-check-box-bounce-in 0.8s;
  }
  .custom-check-box-bounce-leave-active {
    // animation: bounce-in 0.4s reverse;
  }

  @keyframes custom-check-box-bounce-in {
    0% {
      transform: scale(0.7);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
}
</style>
